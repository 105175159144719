// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { ref, getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBBP1AvBVsR9QmhXB8pVUy5oNtY6501Vjc",
  authDomain: "payment-gateway-aeb4e.firebaseapp.com",
  projectId: "payment-gateway-aeb4e",
  storageBucket: "payment-gateway-aeb4e.appspot.com",
  messagingSenderId: "1054497373072",
  appId: "1:1054497373072:web:c67b33acb5a401d768d115",
  databaseURL:
    "https://payment-gateway-aeb4e-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const txnRef = ref(db, "transactions");

export { app, db, txnRef };
